<template>
  <v-layout wrap v-if="content">
    <v-flex xs12
      v-if="bookingWorkspacesID"
    >
      <v-select
        v-model="content.AttendanceWorkspaceID"
        item-text="Name"
        item-value="ID"
        :items="bookingWorkspacesID"
        :label="$t('booking.reservations.workspace', locale)"
        outlined
        @change="handleChangeIsModified"
        hide-details
        style="margin-bottom: 10px;"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'Form49Attendance',
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    bookingWorkspacesID: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    isNew () {
      return this.editedItem.ID === 'new'
    },
    languages () {
      return this.$store.state.app.languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
  },
  mounted () {
      console.log(this.editedItem)
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/contentmanager-book/`)
        .then(response => {
          this.bookingWorkspacesID = response
        })
      if (this.isNew) {
        this.content = this.editedItem
        this.onChangeIsModified(this.content)
      } else {
        this.content = this.editedItem
      }
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>
<style scoped>
  .allergens-main {
    width: 100%;
  }
  .allergens-item {
    float: left;
    padding-right: 10px;
  }
</style>

